<template>
    <b-card-code title="Router List">
        <b-link :to="{ name: 'isp-router-create'}"
                class="nav-link float-left">
            <feather-icon
                icon="PlusIcon"
                size="21"
            />
            Add New
        </b-link>
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block"
                        placeholder="Search"
                        type="text"
                    />
                </div>
            </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
            v-if="rows.length > 0"
            :columns="columns"
            :pagination-options="{
        enabled: false,
        perPage:pageLength
      }"
            :rows="rows"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        >
            <template
                slot="table-row"
                slot-scope="props"
            >
            <div
          v-if="props.column.field === 'not_billing'"
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: 'not-in-billing-users',
              params: { routerId: props.row.id },
            }"
            class="font-weight-bold"
          >
            
            <button class="btn btn-primary" >Not in Billing</button> 

          </b-link>
          <br>
          <b-link
            :to="{
              name: 'not-in-router-users',
              params: { routerId: props.row.id },
            }"
            class="font-weight-bold"
          >
            
          <button class="btn btn-primary" >Not in Router </button> 

          </b-link>
          <b-link
            :to="{
              name: 'offline-users',
              params: { routerId: props.row.id },
            }"
            class="font-weight-bold"
          >
            
          <button class="btn btn-primary" style="margin: 0.2em;" >Offline Users </button> 

          </b-link>
        </div>
                <div
                    v-if="props.column.field === 'indicator'"
                    class="text-nowrap text-center"
                >
                  <span v-if="props.row.is_connected" class="led green blink"></span>
                  <span v-else class="led red blink"></span>
                </div>
                <!-- Column: Name -->
                <div
                    v-if="props.column.field === 'router_name'"
                    class="text-nowrap"
                >

                    <span class="text-nowrap">{{ props.row.router_name }}</span>
                </div>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
                no-caret
                toggle-class="text-decoration-none"
                variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                    class="text-body align-middle mr-25"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item :to="'/isp/router-edit/'+ props.row.id">
                <feather-icon
                    class="mr-50"
                    icon="Edit2Icon"
                />
                   <span>Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

                <!-- Column: Common -->
                <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['10','15','20']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :per-page="pageLength"
                            :total-rows="props.total"
                            :value="1"
                            align="right"
                            class="mt-1 mb-0"
                            first-number
                            last-number
                            next-class="next-item"
                            prev-class="prev-item"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
      <div v-else class="d-flex justify-content-center align-items-center">
        <span class="text-center mr-3" style="font-size: 26px">Checking All of your Routers, Settings & Connections, Please Wait</span>
        <div class="loader"></div>
      </div>
    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store'
import {ROUTER_LIST} from '@core/services/api.js'
import $ from "jquery";

export default {
    components: {
        BLink,
        BCardCode,
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: '',
                    field: 'indicator',
                },
                {
                    label: 'Router Name',
                    field: 'router_name',
                    // filterOptions: {
                    //     enabled: true,
                    //     placeholder: 'Search Route Name',
                    // },
                },
                {
                    label: 'IP Address',
                    field: 'ip_address',
                    // filterOptions: {
                    //     enabled: true,
                    //     placeholder: 'Search IP Address',
                    // },
                },
                {
                    label: 'Port',
                    field: 'api_port',
                    // filterOptions: {
                    //     enabled: true,
                    //     placeholder: 'Search Port',
                    // },
                },
                {
                    label: 'List',
                    field: 'not_billing',
                },
                {
                    label: 'User Name',
                    field: 'username',
                    // filterOptions: {
                    //     enabled: true,
                    //     placeholder: 'Search UserName',
                    // },
                },
                {
                    label: 'Status',
                    field: 'status',
                    // filterOptions: {
                    //     enabled: true,
                    //     placeholder: 'Search Status',
                    // },
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            isp_id:null,
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current: 'light-primary',
                Professional: 'light-success',
                Rejected: 'light-danger',
                Resigned: 'light-warning',
                Applied: 'light-info',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    mounted() {
        this.isp_id = $("#ispListId option:selected").val();
        this.routerList(this.isp_id);
    let instance = this;
    $("#ispListId").change(function () {
         this.isp_id = $(this).children("option:selected").val();
         instance.routerList(this.isp_id);
    });
    },
    methods:{
        routerList(ispId){ 
            this.$http.get(process.env.VUE_APP_BASEURL + ROUTER_LIST + "?isp_id="+ispId)
            .then(res => {
                this.rows = res.data.data
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
// Inspired by: https://github.com/aus/led.css
@mixin ledColor($primaryColor, $secondaryColor) {
  background-color: $primaryColor;
}

@mixin blackLed($dimmedColor) {
  @include ledColor($dimmedColor, $dimmedColor);
}

@mixin led($name, $primaryColor, $secondaryColor, $dimmedColor, $blinkDuration) {
  &.#{$name} {
    @keyframes blink-#{$name} {
      from {
        @include blackLed($dimmedColor);
      }
      25% {
        @include blackLed($dimmedColor);
      }
      30% {
        @include ledColor($primaryColor, $secondaryColor);
      }
      75% {
        @include ledColor($primaryColor, $secondaryColor);
      }
      80% {
        @include blackLed($dimmedColor);
      }
      to {
        @include blackLed($dimmedColor);
      }
    }

    @include ledColor($primaryColor, $secondaryColor);

    &.blink {
      animation: blink-#{$name} $blinkDuration infinite;
    }
  }
}

.led {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);

  &.pulse {
    animation: pulse 4s infinite;
  }

  @include led('red', #f00, #600, #ac1f1f, 2.5s);   // Red LED with dimmed red color for blackLed
  @include led('green', #61b400, #060, #c9d3c0, 0.25s); // Green LED with dimmed green color for blackLed
}

</style>
<style>
/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  height: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
  33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
  66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
  100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
</style>